import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import Typist from 'react-typist'
import Logo from '../assets/kematixDigitalLogo.png'
import KematixDigitalLogoTwitterLogo from '../assets/KematixDigitalTwitterLogo.png'
import KematixDigitalLogoInstagramLogo from '../assets/KematixDigitalInstagramLogo.png'
import KematixDigitalLogoFacebookLogo from '../assets/KematixDigitalfacebooklogo.png'
import KematixDigitalLogoLinkedInLogo from '../assets/KematixDigitalLinkedInLogo.png'

const ServiceH2 = styled.h2`
  font-style: normal;
  font-weight: 200;
  font-size: 25px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #1b1d2a;
`
const ContactButton = styled(Link)`
  margin-top: 20px;
  margin-bottom: 20px;
  height: 3em;
  background: #f1b631;
  border: 0.5px solid #f1b631;
  box-sizing: border-box;
  border-radius: 52px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-bottom: 15px;
  position: absolute;
  bottom: 0;
  width: 226px;
  position: initial;
  margin-bottom: 20px;
  :hover {
    text-decoration: none;
  }
`
const Footer = styled.div`
  background-color: #1b1d2a;
  display: flex;
  justify-content: center;
  color: white;
`
const FooterContainer = styled.div`
  display: flex;
  width: 1200px;
  justify-content: space-between;
  padding-left: 40px;
  padding-right: 40px;
  @media only screen and (max-width: 1199px) {
    width: unset;
    justify-content: flex-end;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
  }
`
const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 1197px) {
    margin: auto;
    align-items: center;
    text-align: center;
  }
`
const Talking = styled.h2`
  color: white;
`
const LogoImage = styled.img`
  width: 250px;
`
const MovingText = styled.p``
const SocialMedias = styled.div`
  display: flex;
  flex-direction: row;
`
const SocialMediaIcon = styled.img`
  width: 33px;
  filter: grayscale(100%);
  margin: 8px;
`
const RightSide = styled.div`
  display: flex;
  margin-top: 1.414rem;
  @media only screen and (max-width: 1197px) {
    margin: auto;
    text-align: center;
    font-size: 19px;
    margin-right: auto;
}
  }
  @media only screen and (max-width: 491px) {
    font-size: 13px;
}
  }
`
const AboutUsSection = styled.div`
  padding: 0;
  padding: 0px 34px 20px 0px;
  ul {
    padding: 0;
    list-style-type: none;
  }
`
const DirectorySection = styled.div`
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    color: white;
    text-decoration: none;
  }
  padding: 0;
  padding: 0px 34px 20px 0px;
`
const ServicesSection = styled.div`
  ul {
    padding: 0;
    list-style-type: none;
  }
  padding: 0;
  padding: 0px 0px 0px 0px;
  @media only screen and (max-width: 491px) {
    padding: 0px 0px 0px 0px;
  }
`

const DirectoryLink = styled(Link)`
  color: white;
  :hover {
    color: white;
    text-decoration: none;
  }
`
const CopyrightText = styled.p``
const StyledTypist = styled(Typist)`
  margin-bottom: 10px;
  display: flex;
  font-size: 15px;
`
const Directories = styled.div`
  display: flex;
  flex-direction: column;
`
const descriptions = ['Software Experts You Can Count On']
export default () => {
  return (
    <Footer>
      <FooterContainer>
        <LeftSide>
          <Talking>Let's talk about how we can help you</Talking>
          <ContactButton to="/contact-us">
            <ServiceH2>Contact Us</ServiceH2>
          </ContactButton>{' '}
          <div style={{ flex: 1 }} />
          <LogoImage alt="Kematix Digital Footer Icon" src={Logo} />
          <MovingText />
          <SocialMedias>
            <a href="https://twitter.com/KematixD" target="_blank" rel="noreferrer">
              {' '}
              <SocialMediaIcon src={KematixDigitalLogoTwitterLogo} alt="Kematix Digital Icon For Twitter" />
            </a>
            <a href="https://www.instagram.com/kematixdigital/" target="_blank" rel="noreferrer">
              <SocialMediaIcon src={KematixDigitalLogoInstagramLogo} alt="Kematix Digital Icon For Instagram" />
            </a>
            <a
              href="https://www.facebook.com/Kematix-Digital-106772764702159/?view_public_for=106772764702159"
              target="_blank"
              rel="noreferrer"
            >
              <SocialMediaIcon
                src={KematixDigitalLogoFacebookLogo}
                style={{ borderRadius: '12px' }}
                alt="Kematix Digital Icon For Instagram"
              />
            </a>
            <a href="https://www.linkedin.com/company/kematix-digital/?viewAsMember=true" target="_blank" rel="noreferrer">
              <SocialMediaIcon src={KematixDigitalLogoLinkedInLogo} alt="Kematix Digital Icon For LinkedIn" />
            </a>
          </SocialMedias>
          <StyledTypist>
            {descriptions.map((description, index) => {
              return (
                <div key={index}>
                  <span>{description}</span>
                  {index < descriptions.length - 1 ? <Typist.Backspace ms={10000} delay={2000} count={description.length} /> : <></>}
                </div>
              )
            })}
          </StyledTypist>{' '}
          <CopyrightText>&copy; Copyright {new Date().getFullYear()}, Kematix Digital Inc.</CopyrightText>
        </LeftSide>
        <RightSide>
          <DirectorySection>
            <p style={{ fontWeight: 'bold' }}>Directory</p>
            <ul>
              <li>
                <DirectoryLink to="/">Home</DirectoryLink>
              </li>
              <li>
                <DirectoryLink to="/#kematix-services">Services</DirectoryLink>
              </li>
              <li>
                <DirectoryLink to="/software-development-portfolio">Portfolio</DirectoryLink>
              </li>
              <li>
                <DirectoryLink to="/contact-us">Contact Us</DirectoryLink>
              </li>
            </ul>
          </DirectorySection>

          <ServicesSection>
            <p style={{ fontWeight: 'bold' }}>Our Services</p>
            <ul>
              <li>AI Development</li>
              <li>Mobile App Development</li>
              <li>Custom Web App / Progressive Web App Development</li>
              <li>Enterprise Software / API Development</li>
              <li>Consulting</li>
            </ul>
          </ServicesSection>
        </RightSide>
      </FooterContainer>{' '}
      <Directories />{' '}
    </Footer>
  )
}
